import React, { Component } from 'react';
import ShareCard from "./sharecard";

const items = [
    {
        title: 'Raviteja Kommalapati',
        description: 'There is a spot remaining in the T-Mobile plan, priced at $50 per month.'
    },
    {
        title: 'Chaitanya Kommalapati',
        description: '2 spots available in the Netflix Family plan, priced at $10 per month.'
    },
    {
        title: 'Shruthi',
        description: 'Looking for a person to share an apartment at Avalon Cahill Park 2b2b. $650 per month, in a 3-share room.'
    },
    {
        title: 'Ramya',
        description: 'There is one available spot in a room within a 2-bedroom, 2-bathroom apartment at 101 San Fernando Apartment.'
    },

    {
        title: 'Manoj Kumar',
        description: 'There is a spot remaining in the T-Mobile plan, priced at $50 per month.'
    },
    {
        title: 'Mithra Vallabaneni',
        description: '2 spots available in the Netflix Family plan, priced at $10 per month.'
    },
    {
        title: 'Poojith',
        description: 'Looking for a person to share an apartment at Avalon Cahill Park 2b2b. $650 per month, in a 3-share room.'
    },
    {
        title: 'Abhishek',
        description: 'There is one available spot in a room within a 2-bedroom, 2-bathroom apartment at 101 San Fernando Apartment.'
    },
];

class Shares extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div style={{ height: `calc(100vh - 64px)`, overflowY: 'scroll', scrollbarWidth: 'thin' }}>
            <div style={{ display: 'flex', gap: '20px',flexDirection: 'column' }}>
                {items.map((item, index) => (
                    <ShareCard key={index} title={item.title} description={item.description} />
                ))}
            </div>
            </div>
        );
    }
}

export default Shares;
