import Shares from "./components/shares";
import {Grid,Divider} from "@mui/material";
import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import NavigationBar from "./components/navigationbar";

const StyledTextField = styled(TextField)`
  width: 100%;
  border-radius: 7px;
  margin-top: 20%;
  margin-bottom: 2%;
`;
function App() {
  return (
    <div className="App">
      <NavigationBar/>
      <Grid container spacing={1}>
        <Grid item xs={4}>
        </Grid>
        <Grid item xs={4}>
          <div>
            <StyledTextField
                id="outlined-multiline-static"
                label="Write a Share"
                multiline
                rows={4}
            />
          </div>
          <Divider light />
          <Shares/>
        </Grid>
        <Grid item xs={4}>
        </Grid>
      </Grid>
    </div>
  );
}

export default App;
